import React from 'react';
import { Helmet } from 'react-helmet';

function Home() {
    return (
        <>
            <section id='hero' class='d-flex align-items-center'>
                <Helmet>
                    <title>App Wings | Mobile & Web App Development</title>
                </Helmet>
                <div class='container'>
                    <div class='row'>
                        <div class='col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1' data-aos='fade-up'>
                            <div>
                                <h1>All Apps; All digital</h1>
                                <h2>We design amd develop mobile and web applications that looks great on iOS and Android devices.</h2>
                                <a href='https://play.google.com/store/apps/dev?id=5035077054088636313' target='_blank' rel='noopener noreferrer' class='download-btn'><i class='bx bxl-play-store'></i> Google Play</a>
                                <a href='https://apps.apple.com/us/developer/appwings/id1607797972' target='_blank' rel='noopener noreferrer' class='download-btn'><i class='bx bxl-apple'></i> App Store</a>
                            </div>
                        </div>
                        <div class='col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img' data-aos='fade-up'>
                            <img src='assets/img/hero-img.png' class='img-fluid' alt='' />
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Home;