import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from 'react-router-dom';

import Home from './Home';
import About from './About';
import Services from './Services'
import Contact from './Contact';
import Privacy from './Privacy';
import Support from './Support';
import Blogs from './Blogs';
import Blog from './Blog';
import CaseStudies from './CaseStudies';
import CaseStudy from './CaseStudy';
import Terms from './Terms';
import Apps from './Apps';
import App from './App';

function Header() {
  // const currentPathName = window.location.pathname;

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/support' element={<Support />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blog/:slug' element={<Blog />} />
          <Route path='/case-studies' element={<CaseStudies />} />
          <Route path='/case-study/:slug' element={<CaseStudy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/apps' element={<Apps />} />
          <Route path='/app' element={<App />} />
        </Routes>
      </Router>
    </>
  );
}

export default Header;