import React from "react";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import blogsData from "../data/blogs.json";

function Blog() {
  const { slug } = useParams();

  return (
    <>
      <div>
        {blogsData.filter(blog => blog.sl === `${slug}`).map(filteredBlog => (
          <div>
            <Helmet>
              <title>Blog - {filteredBlog.ti}</title>
              <meta content="{filteredBlog.md}" />
              <meta content="{filteredBlog.mk}" />
            </Helmet>
            <section class="breadcrumbs">
              <div class="container">

                <div class="d-flex justify-content-between align-items-center">
                  <h2>Blog</h2>
                  <ol>
                    <li><a href="index.html">Home</a></li>
                    <li><a href="/blogs">Blogs</a></li>
                    <li>{filteredBlog.ti}</li>
                  </ol>
                </div>

              </div>
            </section>

            <section id="about" class="about section-bg">
              <div class="container" data-aos="fade-up">

                <div class="section-title">
                  <h2>Blog</h2>
                  <h3>{filteredBlog.ti}</h3>
                  <p>{filteredBlog.md}</p>
                </div>
                <div class="row">
                  <div class="col-lg-12" data-aos="fade-right" data-aos-delay="100">
                    <img src={`../assets/img/blogs/${filteredBlog.iu}`} class="img-fluid blog_image_height" alt={`${filteredBlog.ti}`} />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    <div dangerouslySetInnerHTML={{ __html: filteredBlog.ds }}></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    <p><b>Published Date:</b> {filteredBlog.dt}</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}
      </div>
    </>
  );
}

export default Blog;