import React from 'react';
import { Helmet } from 'react-helmet';

function Contact() {
    return (
        <>
            <section id='contact' class='contact newsletter'>
                <Helmet>
                    <title>Contact us</title>
                </Helmet>
                <div class='container' data-aos='fade-up'>

                    <div class='section-title'>
                        <h2>Contact</h2>
                        <p>Reach us for all your mobile app and web development requirements.</p>
                    </div>

                    <div class='row'>

                        <div class='col-lg-6'>
                            <div class='row'>

                                 <div class='col-lg-6 info'>
                                    <i class='bx bx-envelope'></i>
                                    <h4>Email Us</h4>
                                    <p><a href='mailto:info@appwings.com' target='_blank' rel='noopener noreferrer'>info@appwings.com</a></p>
                                </div>

                            </div>
                        </div>

                        <div class='col-lg-6'>

                            <img src='assets/img/contact.png' class='img-fluid' alt='' />

                            {/* <!-- <form action='forms/contact.php' method='post' role='form' class='php-email-form' data-aos='fade-up'>
                    <div class='form-group'>
                        <input placeholder='Your Name' type='text' name='name' class='form-control' id='name' required>
                    </div>
                    <div class='form-group mt-3'>
                        <input placeholder='Your Email' type='email' class='form-control' name='email' id='email' required>
                    </div>
                    <div class='form-group mt-3'>
                        <input placeholder='Subject' type='text' class='form-control' name='subject' id='subject' required>
                    </div>
                    <div class='form-group mt-3'>
                        <textarea placeholder='Message' class='form-control' name='message' rows='5' required></textarea>
                    </div>
                    <div class='my-3'>
                        <div class='loading'>Loading</div>
                        <div class='error-message'></div>
                        <div class='sent-message'>Your message has been sent. Thank you!</div>
                    </div>
                    <div class='text-center'><button type='submit'>Send Message</button></div>
                    </form> --> */}
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Contact;