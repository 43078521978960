import React from 'react';
import { Helmet } from 'react-helmet';

function App() {
  const queryParams = new URLSearchParams(window.location.search)
  var term = queryParams.get("app")
  term = term.split("/")
  var appId = term[1].replace(/^\D+/g, '');

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const url = "https://itunes.apple.com/lookup?id=" + appId;
    var headers = { "Access-Control-Allow-Origin": "*" }
    fetch(url, {
      method: "GET",
      crossDomain: true,
      crossOrigin: true,
      mode: 'cors',
      credentials: 'include',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => setData(json['results']))
      .catch((error) => console.log(error));
  }, []);

  // alert(data);

  React.useEffect(() => {
    if (data.length !== 0) {
      setIsLoading(false);
    }
    console.log(data);
  }, [data]);

  return (
    <>
      {isLoading ? (
        <h3>Loading...</h3>
      ) : (
        data.map((results) => (
          <div>
            <section id='details' className='details'>
              <Helmet>
                <title>{results.trackName} App Page</title>
              </Helmet>
              <div className='container'>
                <div className='section-title'>
                  <h2>{results.trackName}</h2>
                  <div className="row content">
                    <div className="col-md-4 aos-init aos-animate" data-aos="fade-right">
                      <img className="img-fluid" src={results.artworkUrl512} alt={results.trackName} style={{ width: '50%' }} />
                    </div>
                    <div className="col-md-4 pt-1 aos-init aos-animate" data-aos="fade-up">
                      <br /><br />
                      <p style={{ 'textAlign': 'left' }}>
                        <b>Price:</b> {results.formattedPrice} <br /> <br />
                      </p>
                      <p style={{ 'textAlign': 'left' }}><b>Category:</b> {results.primaryGenreName} <br /> <br />
                      </p>
                    </div>
                    <div className="col-md-4 aos-init aos-animate" data-aos="fade-right">
                      <br /><br />
                      <p style={{ 'textAlign': 'left' }}>
                        <b>Content Rating:</b> {results.contentAdvisoryRating} <br /> <br />
                      </p>
                      <p style={{ 'textAlign': 'left' }}>
                        <a href={results.trackViewUrl} target="_blank" rel="noopener noreferrer"><img src="assets/img/btnAppStore.png" className="img-fluid" alt="" style={{ 'width': '150px' }} /></a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12 pt-1 aos-init aos-animate" data-aos="fade-up">
                      <h5 style={{ 'textAlign': 'left' }}>Description</h5>
                      <p style={{ 'textAlign': 'justify', whiteSpace: "pre-wrap" }}>{results.description}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row content">
                    <div className="col-md-12 pt-1 aos-init aos-animate" data-aos="fade-up">
                      <h5 style={{ 'textAlign': 'left' }}>App Screenshots</h5>
                      <div className="row no-gutters">
                        {results.screenshotUrls.map((c) => (
                          <div className="col-lg-2 box aos-init aos-animate" data-aos="fade-right">
                            <div className="card" style={{ 'width': '100%', "borderRadius": "5%", "padding": '10px;', "margin": '50px;' }}>
                              <img className="card-img-top" src={c} alt={results.trackName} style={{ "border-radius": "5%" }} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))
      )}
    </>
  );
}

export default App;