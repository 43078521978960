import React from 'react';
import { Helmet } from 'react-helmet';

function Services() {
    return (
        <>
            <section id='features' class='features'>
                <Helmet>
                    <title>Our Offerings</title>
                </Helmet>
                <div class='container'>

                    <div class='section-title'>
                        <h2>Services</h2>
                        <p>We offer a wide array of services for clients from numerous industries and walks of life. With all these services, we strive for ultimate client satisfaction. Choose from our wide portfolio of services as per your requirements for customized solutions.</p>
                    </div>

                    <div class='row no-gutters'>
                        <div class='col-xl-7 d-flex align-items-stretch order-2 order-lg-1'>
                            <div class='content d-flex flex-column justify-content-center'>
                                <div class='row'>
                                    <div class='col-md-6 icon-box' data-aos='fade-up'>
                                        <i class='bx bx-mobile-alt'></i>
                                        <h4>Apple iOS</h4>
                                        <p>Our team of developers is masterful in the development of applications for the iOS. We are well aware of all the standards prevailing in the industry for iOS development.</p>
                                        <ul class='services'>
                                            <li>XCode</li>
                                            <li>Core Data</li>
                                            <li>SQLite</li>
                                            <li>JSON</li>
                                        </ul>
                                    </div>
                                    <div class='col-md-6 icon-box' data-aos='fade-up'>
                                        <i class='bx bx-mobile'></i>
                                        <h4>Google Android</h4>
                                        <p>Android is a relatively key technology player in the division of mobile operating systems and it has a very high & significant share of the market.</p>
                                        <ul class='services'>
                                            <li>Android Studio</li>
                                            <li>Core Data</li>
                                            <li>SQLite</li>
                                            <li>JSON</li>
                                        </ul>
                                    </div>
                                    <div class='col-md-6 icon-box' data-aos='fade-up'>
                                        <i class='bx bxl-amazon'></i>
                                        <h4>Amazon Apps</h4>
                                        <p>The Amazon platform is greatly popular in a particular niche of users due to its uniqueness among the other smart phone platforms.</p>
                                        <ul class='services'>
                                            <li>Android Studio</li>
                                            <li>Core Data</li>
                                            <li>SQLite</li>
                                            <li>JSON</li>
                                        </ul>
                                    </div>
                                    <div class='col-md-6 icon-box' data-aos='fade-up'>
                                        <i class='bx bx-globe'></i>
                                        <h4>Web Apps</h4>
                                        <p>We believe in creating rich web design that reflects your brand image and personality through the design and the efficiency it provides.</p>
                                        <ul class='services'>
                                            <li>ReactJS, HTML5, CSS, Java script</li>
                                            <li>PHP, Wordpress, Laravel</li>
                                            <li>SQLite</li>
                                            <li>JSON</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2' data-aos='fade-left' data-aos-delay='100'>
                            <img src='assets/img/features.svg' class='img-fluid' alt='' />
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Services;