import React from 'react';
import { Helmet } from 'react-helmet';

function Privacy() {
  return (
    <>
      <section id='details' class='details'>
        <Helmet>
          <title>App Wings | Privacy Policy</title>
        </Helmet>
        <div class='container'>
          <div class='section-title'>
            <h2>Privacy Policy</h2>
            <p>
              We do not store, rent, sell or share any emails,phone numbers or any other personal information collected on any of our apps with third parties for any purposes.
            </p><br />

            <p>
              <b>Changes to Our Privacy Policy:</b> As our organization and services evolve, this Privacy Policy may be revised to reflect those changes, where applicable. We therefore reserve the right to amend this Privacy Policy at any time, for any reason. Notice of such amendments will be posted whenever needed. Users are encouraged to check the website frequently to review the current Privacy Policy in effect.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy;