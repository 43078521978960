import React from "react";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import caseStudiesData from "../data/case_studies.json";

function CaseStudy() {
  const { slug } = useParams();

  return (
    <>
      <div>
        {caseStudiesData.filter(case_study => case_study.sl === `${slug}`).map(filteredCaseStudy => (
          <div>
            <Helmet>
              <title>Case Study - {filteredCaseStudy.ti}</title>
            </Helmet>
            <section class="breadcrumbs">
              <div class="container">

                <div class="d-flex justify-content-between align-items-center">
                  <h2>Case Study</h2>
                  <ol>
                    <li><a href="index.html">Home</a></li>
                    <li><a href="/case-studies">Case Studies</a></li>
                    <li>{filteredCaseStudy.ti}</li>
                  </ol>
                </div>

              </div>
            </section>
            <section id="about" class="about section-bg">
              <div class="container" data-aos="fade-up">

                
              <div class="row">
                  
                </div>

                <div class="section-title">
                  <h2>Case Study</h2>
                  <h3>{filteredCaseStudy.ti}</h3>
                  <p>{filteredCaseStudy.md}</p>
                </div>

                <div class="row">
                  <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
                    <img src={`../assets/img/case_studies/${filteredCaseStudy.iu}`} class="img-fluid" alt={`${filteredCaseStudy.ti}`} />
                  </div>
                  <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    <h3>We take care of your online presence.</h3>
                    <p class="fst-italic">
                      {filteredCaseStudy.ds}
                    </p>
                    <p>Published Date: {filteredCaseStudy.dt}</p>
                  </div>
                </div>

              </div>
            </section>
          </div>
        ))}
      </div>
    </>
  );
}

export default CaseStudy;