import React from 'react';
import { Helmet } from 'react-helmet';

function Apps() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const url = "https://itunes.apple.com/lookup?id=1743000234&entity=software";
    var headers = { "Access-Control-Allow-Origin": "*" }
    fetch(url, {
      method: "GET",
      crossDomain: true,
      crossOrigin: true,
      mode: 'cors',
      credentials: 'include',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => setData(json['results']))
      .catch((error) => console.log(error));
  }, []);

  React.useEffect(() => {
    if (data.length !== 0) {
      setIsLoading(false);
    }
    console.log(data);
  }, [data]);

  return (
    <>
      <section id='details' class='details'>
        <Helmet>
          <title>Our iOS Portfolio</title>
        </Helmet>
        <div className='container'>
          <div className='section-title'>
            <h2>Our Apple Apps</h2>
            <div className="row no-gutters">
              {isLoading ? (
                <h3>Loading...</h3>
              ) : (
                data.slice(1).map((results) => (
                  <div className="col-lg-2 box aos-init aos-animate" data-aos="fade-right">
                    <div className="card" style={{ 'width': '100%', "borderRadius": "5%", "padding": '10px;', "margin": '50px;' }}>
                      <img className="card-img-top" src={results.artworkUrl512} alt={results.trackName} style={{ "border-radius": "5%" }} />
                      <div className="card-body">
                        {/* <h5 className="card-title" style={{'text-size-adjust': '80%;'}}>{results.trackName}</h5> */}
                        <p className="card-text">{results.formattedPrice}</p>
                        <a href={`/app?app=${results.trackName.replace(" ", "-")}/id${results.trackId}`} className="btn btn-primary">See App</a>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Apps;