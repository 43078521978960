import React from 'react';
import { Helmet } from 'react-helmet';

function Support() {
    return (
        <>
            <section id='details' class='details'>
                <Helmet>
                    <title>App Wings | Support</title>
                </Helmet>
                <div class='container'>
                    <div class='section-title'>
                        <h2>Email Support</h2>
                        <p>
                            Please contact us for any support via <a href='mailto:info@appwings.com' target='_blank' rel='noopener noreferrer'>info@appwings.com</a>.
                        </p><br />
                    </div>
                </div>
            </section>
        </>
    );
}

export default Support;