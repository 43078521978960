import React from 'react';

function Footer() {
    return (
        <>
            <footer id='footer'>

                <div class='footer-top'>
                    <div class='container'>
                        <div class='row'>

                            <div class='col-lg-3 col-md-6 footer-contact'>
                                <h3>App Wings</h3>
                                <p>
                                    <strong>Email:</strong> <a href='mailto:support@appwings.com' target='_blank' rel='noopener noreferrer'>support@appwings.com</a><br />
                                </p>
                            </div>

                            <div class='col-lg-3 col-md-6 footer-links'>
                                <h4>Organization</h4>
                                <ul>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/'>Home</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/about'>About</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/apps'>App Portfolio</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/services'>Services</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/contact'>Contact</a></li>
                                </ul>
                            </div>
                            <div class='col-lg-3 col-md-6 footer-links'>
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/blogs'>Blogs</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/support'>Support</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/terms'>Terms & Conditions</a></li>
                                    <li><i class='bx bx-chevron-right'></i> <a href='/privacy'>Privacy Policy</a></li>
                                </ul>
                            </div>

                            <div class='col-lg-3 col-md-6 footer-links'>
                                <h4>Our Social Networks</h4>
                                <p></p>
                                <div class='social-links mt-3'>
                                    <a href='https://twitter.com/appwings' target='_blank' class='twitter' rel='noopener noreferrer'><i class='bx bxl-twitter'></i></a>
                                    <a href='https://facebook.com/appwingscom' target='_blank' class='facebook' rel='noopener noreferrer'><i class='bx bxl-facebook'></i></a>
                                    <a href='https://instagram.com/app_wings' target='_blank' class='instagram' rel='noopener noreferrer'><i class='bx bxl-instagram'></i></a>
                                    <a href='https://youtube.com/appwings' target='_blank' class='google-plus' rel='noopener noreferrer'><i class='bx bxl-youtube'></i></a>
                                    <a href='https://www.linkedin.com/showcase/app-wings' target='_blank' class='linkedin' rel='noopener noreferrer'><i class='bx bxl-linkedin'></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class='container py-4'>
                    <div class='copyright'>
                        &copy; 2022 <strong><span>App Wings</span></strong>. All Rights Reserved.
                    </div>
                    <div class='credits'>
                        Powered by <a href='https://w3nest.com/' target='_blank' rel='noopener noreferrer'><b>W3Nest.com</b></a>.
                    </div>
                </div>
                <div class="container py-4">
          <div class="credits text-left"><b>Partners & Channels:</b> <span><a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://w3nest.com">W3Nest</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://anandtechmedia.com">Anand Tech Media</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://pixoho.com">Pixoho</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://namkural.com">Nam Kural</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://cineminnal.com">Cine Minnal</a></span>
          </div>
          <div class="credits">
          
          </div>
        </div>
            </footer>
        </>
    );
}

export default Footer;