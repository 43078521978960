import React from 'react';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
      <section id='details' class='details'>
        <Helmet>
          <title>About Us</title>
        </Helmet>
        <div class='container'>
          <div class='section-title'>
            <h2>About</h2>
            <p>
              'App Wings' is a brand of "Anand Tech Media", which has been involved in development of apps for various mobile devices such as iPhone, Android etc. Over the years App Wings has implemented many innovative applications to meet the production requirements of the key customers. App Wings has created platforms for various smart phone applications for iPhone, Android etc.
            </p>
            <p>
              App Wings strongly believes in respecting all the requirements of its clients with the reliability and service delivery the highest.
            </p>
          </div>
          <div class='row content'>
            <div class='col-md-4 order-1 order-md-2' data-aos='fade-left'>
              <img src='assets/img/details-2.png' class='img-fluid' alt='' />
            </div>
            <div class='col-md-8 pt-5 order-2 order-md-1' data-aos='fade-up'>
              <h3>Mission</h3>
              <p class='fst-italic'>
                Our company is in the business of developing mobile applications because every member of our team loves to innovate and keeps on acquiring new resources to do so. We work in collaboration with our clients to know what they need from the application and make the experience enriching for them. We have contributed to the success of many of our clients by offering them just what they need. Being a young company, we believe in being innovative and continuous learning for the ultimate satisfaction of our clients.
              </p>
            </div>
          </div>

          <div class='row content'>
            <div class='col-md-4' data-aos='fade-right'>
              <img src='assets/img/details-3.png' class='img-fluid' alt='' />
            </div>
            <div class='col-md-8 pt-5' data-aos='fade-up'>
              <h3>Vision</h3>
              <p>Our vision is based on the primary objective of enhancing the ROI of our clients through our applications, no matter what industry they work in. We push the limits with our innovation and encourage our developers to keep on learning and improving so that they can stay up to date with the new developments. This creates maximum benefit for the developers, clients as well as the company.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;